import React from 'react'
import styled from 'styled-components'

import Telegram from './Telegram'
import Facebook from './Facebook'
import Youtube from './Youtube'
import Instagram from './Instagram'
import {ContactButtonLink} from './ContactButton'

interface Props {
  className?: string
}

export const SocialMediasContainer = styled.div`
  display: flex;

  & > ${ContactButtonLink} {
    margin-right: 10px;
  }
`

const SocialMedias: React.FunctionComponent<Props> = ({className}) => (
  <SocialMediasContainer className={className}>
    <Telegram />
    <Facebook />
    <Youtube />
    <Instagram />
  </SocialMediasContainer>
)

export default SocialMedias
