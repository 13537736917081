import React from 'react'
import styled from 'styled-components'

interface ContactButtonContentProps {
  svgId: string
  text?: string
  tooltip?: string
}

interface Props extends ContactButtonContentProps {
  href?: string
}

const Svg = styled.svg`
  fill: #fff;
  height: 25px;
  width: 25px;
`

const Span = styled.span`
  color: #fff;
  font-size: 10pt;
  margin-left: 0.5em;
`

const ContactButtonDiv = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
`

export const ContactButtonLink = styled.a`
  align-items: center;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  text-decoration: none;

  &:hover {
    ${Svg} {
      fill: #aaa;
    }
  }
`

const ContactButtonContent: React.FunctionComponent<ContactButtonContentProps> = ({
  svgId,
  text,
}) => (
  <>
    <Svg>
      <use xlinkHref={`#${svgId}`} />
    </Svg>
    {text && <Span>{text}</Span>}
  </>
)

const ContactButton: React.FunctionComponent<Props> = ({href, svgId, text, tooltip}) => {
  if (href) {
    return (
      <ContactButtonLink href={href} title={tooltip}>
        <ContactButtonContent svgId={svgId} text={text} />
      </ContactButtonLink>
    )
  }
  return (
    <ContactButtonDiv>
      <ContactButtonContent svgId={svgId} text={text} />
    </ContactButtonDiv>
  )
}

export default ContactButton
