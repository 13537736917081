import React from 'react'
import Svg from 'assets/youtube.svg'
import ContactButton from './ContactButton'

export default () => (
  <ContactButton
    href="https://www.youtube.com/channel/UChpXPoj9aFxx-HsdLfYvp_w"
    svgId={Svg.id!}
    tooltip="YouTube"
  />
)
