import React, {FunctionComponent} from 'react'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

interface MetaAttrs {
  content: string
  name?: string
  property?: string
}

interface Data {
  site: {
    siteMetadata: {
      author: string
      description: string
      keywords: string[]
      lang: string
      title: string
    }
  }
}

const seoQuery = graphql`
  query seo {
    site {
      siteMetadata {
        author
        description
        keywords
        lang
        title
      }
    }
  }
`

const Seo: FunctionComponent = () => (
  <StaticQuery
    query={seoQuery}
    render={(data: Data) => {
      const {author, description, keywords, lang, title} = data.site.siteMetadata
      const metas = [
        {
          name: 'author',
          content: author,
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords.join(', '),
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ] as MetaAttrs[]
      return <Helmet htmlAttributes={{lang}} title={title} meta={metas} />
    }}
  />
)

export default Seo
