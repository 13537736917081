import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import SocialMedias from '../contacts/SocialMedias'
import Tel from '../contacts/Tel'
import Email from '../contacts/Email'

const Wrapper = styled.div`
  left: 5em;
  position: absolute;
  top: 5em;
  z-index: 10;

  @media (max-width: 768px) {
    left: 1em;
    top: 1em;
  }
`

const BurgerButton = styled.button`
  background-color: transparent;
  border: 0 none;
  color: #000;
  cursor: pointer;
  font-family: serif;
  font-size: 40pt;
  font-weight: bold;
`

const Burger: React.FunctionComponent<{onClick: () => void}> = ({onClick}) => (
  <BurgerButton onClick={onClick}>≡</BurgerButton>
)

const BodyDiv = styled.div`
  background-color: #212121;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 179px);
  min-height: 420px;
  padding: 1em;
  position: absolute;
  width: 340px;
`

const TopSection = styled.div`
  padding: 3em;
`

const BottomSection = styled.div`
  margin-top: auto;

  & > * {
    margin-bottom: 0.3em;
  }
`

const MenuItems = styled.ul`
  font-size: 18pt;
  list-style: none;
  margin: 0;
  padding: 0;
`

const MenuItem = styled.li`
  margin: 0 0 0.5em 0;
`

const MenuAnchor = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #fff;
  }
`

const AlignedSocialMedias = styled(SocialMedias)`
  justify-content: end;
  margin-top: 1em;
`

const Body: React.FunctionComponent = () => (
  <BodyDiv>
    <TopSection>
      <MenuItems>
        <MenuItem>
          <MenuAnchor href="/">Заставка</MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor href="/about">О себе</MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor href="/portfolio">Портфолио</MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor href="/contact">Контакты</MenuAnchor>
        </MenuItem>
      </MenuItems>
    </TopSection>
    <BottomSection>
      <Email />
      <Tel />
      <AlignedSocialMedias />
    </BottomSection>
  </BodyDiv>
)

const Menu: React.FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false)
  const onClick = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])
  return (
    <Wrapper>
      <Burger onClick={onClick} />
      {isOpen && <Body />}
    </Wrapper>
  )
}

export default Menu
