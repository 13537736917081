import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
  body {
    background-color: #252525;
    color: white;
    font-family: 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif;
    margin: 0;
    padding: 0;
  }

  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: #fff;
  }

  a:visited {
    color: #ddd;
  }

  a:hover,
  a:active {
    color: #bbb;
  }
`
