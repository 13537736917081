import React from 'react'

import GlobalStyle from '../style/GlobalStyle'
import Menu from '../header/Menu'
import Seo from './Seo'

const Layout: React.FunctionComponent = ({children}) => (
  <div>
    <GlobalStyle />
    <Seo />
    <Menu />
    {children}
  </div>
)

export default Layout
